.strike {
    text-decoration: line-through;
    color: grey;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.7;
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}